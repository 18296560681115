import "../styles/index.scss"
import React, { useState, useRef, useEffect } from "react"
import { Link } from "gatsby"
import addToMailchimp from "gatsby-plugin-mailchimp"
import ReactPixel from "react-facebook-pixel"

const Footer = () => {
  const [email, setEmail] = useState("")
  const [tracking, setTracking] = useState(false)
  const signupButton = useRef(null)
  const [signupButtonMsg, setSignupButtonMsg] = useState("Submit")
  // console.log('email input', email);
  const [isError, setIsError] = useState(false)
  const [isProcessing, setIsProcessing] = useState(false)
  const [inputMsg, setInputMsg] = useState("Email Address*")

  useEffect(() => {
    if (isProcessing) setSignupButtonMsg("Processing")
    else if (!isProcessing) setSignupButtonMsg("Submit")
  }, [isProcessing])

  // console.log('window.fbq', typeof window.fbq);
  const handleSubmit = e => {
    e.preventDefault()
    // window.fbq('track', 'Donate');
    // window.fbq('track', 'AddToCart');
    // window.fbq('track', 'Lead');
    // window.fbq('track', 'Contact');
    // window.fbq('track', 'AddPaymentInfo');
    if (!tracking) {
      // ReactPixel.track("track", "Lead")
      ReactPixel.track("Lead")
    }

    setTracking(true)

    // const windowGlobal = typeof window !== 'undefined' && window
    // then, windowGlobal.localStorage
    // fbTrack('track', 'Lead');

    setIsProcessing(true)
    signupButton.current.setAttribute("disabled", "disabled")
    addToMailchimp(email)
      .then(data => {
        // console.log('returned data', data.msg);
        if (data.result === "error") {
          setIsProcessing(false)
          setIsError(true)
          setEmail("")
          setInputMsg("Sorry, this email cannot be added to the list.")
          signupButton.current.removeAttribute("disabled")
        } else if (data.result === "success") {
          setIsProcessing(false)
          setEmail("")
          setInputMsg(data.msg)
        }
      })
      .catch(err => {
        console.warn("err", err)
      })
  }

  /* 
		* honeypot

		Lot of spam submissions come from web crawlers/bots
	*/

  const handleEmailChange = e => {
    // console.log('e.currentTarget.value', e.currentTarget.value);
    setEmail(e.currentTarget.value)
  }

  // send notifications and emails. also utilize zapier.
  // data-netlify will allow us to capture all the data attributes coming into netlify
  return (
    // <form action="" data-netlify="true" name="registration" onSubmit={handleSubmit} className="footer-container">
    <div className="footer-container">
      <div className="footer-heading-container">
        <div className="footer-heading">
          Celebrating Family and Responsibility
        </div>
      </div>

      <div className="footer-signup-container">
        <div className="footer-signup-heading">No Junk, Just Journals.</div>
        <div className="footer-signup-input-container">
          <form
            onSubmit={handleSubmit}
            className="footer-signup-input-inner-container"
            method="POST"
            action="/thankyou"
            netlify-honeypot="bot-field"
            data-netlify="true"
            name="registration"
          >
            <input type="hidden" name="form-name" value="registration" />
            {/* <label htmlFor="email" /> */}

            <input
              // required
              // pattern={`[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2, 4}$`}
              className="footer-signup-input"
              type="email"
              name="email"
              value={email}
              placeholder={`${inputMsg}`}
              onChange={handleEmailChange}
            />
            {/* {isError && (
							<div
								style={{
									position: 'absolute',
									color: '#fff',
									bottom: '-1.5rem',
									left: 0
								}}
							>
								{inputMsg}
							</div>
						)} */}

            {/* <p className="error" id="error">
							Please enter an email
						</p> */}

            <button
              ref={signupButton}
              type="submit"
              className="footer-signup-button"
            >
              {signupButtonMsg}
            </button>
          </form>
        </div>
      </div>

      <div className="footer-menu-container">
        <div className="footer-menu-inner-container">
          <div className="footer-menu-block-container">
            <Link to="/journals">
              <div className="footer-menu-block-item">Journals</div>
            </Link>
            <Link to="/about">
              <div className="footer-menu-block-item">About</div>
            </Link>
            <Link to="/goodjournals">
              <div className="footer-menu-block-item">Doing Good</div>
            </Link>
          </div>
          <div className="footer-menu-block-container">
            <Link to="/faq">
              <div className="footer-menu-block-item">FAQ</div>
            </Link>
            <Link to="/privacy">
              <div className="footer-menu-block-item">Privacy</div>
            </Link>
            <Link to="/termsofservice">
              <div className="footer-menu-block-item">Terms</div>
            </Link>
          </div>
          <div className="footer-menu-block-container">
            <a
              href="https://www.facebook.com/Cat-Bear-115246910018053/"
              target="_blank"
            >
              <div className="footer-menu-block-item">Facebook</div>
            </a>
            <a href="https://www.instagram.com/catbear.love" target="_blank">
              <div className="footer-menu-block-item">Instagram</div>
            </a>
            <Link to="/contact">
              <div className="footer-menu-block-item">Contact</div>
            </Link>
          </div>
        </div>
      </div>

      <div className="footer-copyright">2021 © Cat & Bear</div>
    </div>
  )
}

export default Footer
