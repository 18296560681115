/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
// import sofiaProBold from '../fonts/SofiaProBold.ttf';
// import sofiaProLight from '../fonts/SofiaProLight.ttf';
// import sofiaProRegular from '../fonts/SofiaProRegular.ttf';

// import CookieConsent from 'react-cookie-consent';

function SEO({ description, lang, meta, image: metaImage, title }) {
	const { site } = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						author
						title
						description
						keywords
					}
				}
			}
		`
	);

	const metaDescription = description || site.siteMetadata.description;
	const image = metaImage && metaImage.src ? `${site.siteMetadata.siteUrl}${metaImage.src}` : null;

	return (
		<Helmet
			htmlAttributes={{
				lang: lang || 'en'
			}}
			title={title}
			titleTemplate={`${site.siteMetadata.title}`}
			meta={[
				{
					name: `description`,
					content: metaDescription
				},
				{
					name: 'keywords',
					content: site.siteMetadata.keywords.join(',')
				},
				{
					property: `og:title`,
					content: title
				},
				{
					property: `og:description`,
					content: metaDescription
				},
				{
					property: `og:type`,
					content: `website`
				},
				{
					name: `twitter:card`,
					content: `summary`
				},
				{
					name: `twitter:title`,
					content: title
				},
				{
					name: `twitter:description`,
					content: metaDescription
				}
			].concat(meta)}
		>
			{/* <link
				rel="preload"
				as="font"
				href={sofiaProBold}
				// href="../fonts/MarvinVisions-Variable.woff2"
				type="font/ttf"
				crossOrigin="anonymous"
			/>
			<link
				rel="preload"
				as="font"
				href={sofiaProLight}
				// href="../fonts/MarvinVisions-Variable.woff2"
				type="font/ttf"
				crossOrigin="anonymous"
			/>
			<link
				rel="preload"
				as="font"
				href={sofiaProRegular}
				// href="../fonts/MarvinVisions-Variable.woff2"
				type="font/ttf"
				crossOrigin="anonymous" */}
			/>
		</Helmet>
	);
}

SEO.defaultProps = {
	lang: `en`,
	meta: [],
	description: ``
};

SEO.propTypes = {
	description: PropTypes.string,
	lang: PropTypes.string,
	meta: PropTypes.arrayOf(PropTypes.object),
	title: PropTypes.string.isRequired
};

export default SEO;
